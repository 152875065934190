import { useEffect, useState } from "react";

import { boosterPlans } from "../../types/General";
import { useLazyGetMyBoosterQuery } from "../../service/Product";

import useTranslation from "../../hooks/Translation";
import { format } from "date-fns";

const MyBooster = () => {
  const translation = useTranslation() as any;

  const [myBoosterState, setMyBoosterState] = useState<boosterPlans>();

  const [myBooster] = useLazyGetMyBoosterQuery();

  const getMyBooster = async () => {
    try {
      const result = await myBooster({}).unwrap();
      if (result?.statusCode === 200) {
        setMyBoosterState(result?.data || null);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getMyBooster();
  }, []);

  return (
    <div className="main">
      <h2>{translation.profile.my_booster}</h2>

      <ul className="followers_lst">
        <li className="flw_lst booster_dtl">
          <div
            className="lst_lft"
            style={{ marginBottom: "10px", color: "#1d1d1d" }}
          >
            <h4 style={{ marginRight: "10px" }}>
              {translation.profile.plan_name || ""} :{" "}
            </h4>
            <h4>{myBoosterState?.planName || "-"}</h4>
          </div>
          <div
            className="lst_lft"
            style={{ marginBottom: "10px", color: "#1d1d1d" }}
          >
            <h4 style={{ marginRight: "10px" }}>
              {translation.profile.plan_price || ""} :{" "}
            </h4>
            <h4>{myBoosterState?.price || "-"}</h4>
          </div>
          <div
            className="lst_lft"
            style={{ marginBottom: "10px", color: "#1d1d1d" }}
          >
            <h4 style={{ marginRight: "10px" }}>
              {translation.profile.plan_validity || ""} :{" "}
            </h4>
            <h4>
              {/* {myBoosterState?.validity || "-"} */}

              {myBoosterState?.validity === 1
                ? `1 ${translation.Globals.week}`
                : myBoosterState?.validity === 2
                ? `2 ${translation.Globals.weeks}`
                : myBoosterState?.validity === 3
                ? `3 ${translation.Globals.months}`
                : myBoosterState?.validity === 4
                ? `6 ${translation.Globals.months}`
                : myBoosterState?.validity === 5
                ? `9 ${translation.Globals.months}`
                : myBoosterState?.validity === 6
                ? `1 ${translation.Globals.year}`
                : ""}
            </h4>
          </div>
          <div
            className="lst_lft"
            style={{ marginBottom: "10px", color: "#1d1d1d" }}
          >
            <h4 style={{ marginRight: "10px" }}>
              {translation.profile.expiry_date || ""} :{" "}
            </h4>
            <h4>
              {myBoosterState?.expiryDate
                ? format(new Date(myBoosterState.expiryDate), "dd/MM/yyyy")
                : ""}
            </h4>
          </div>
          <div
            className="lst_lft"
            style={{ marginBottom: "10px", color: "#1d1d1d" }}
          >
            <h4 style={{ marginRight: "10px" }}>
              {translation.profile.description || ""} :{" "}
            </h4>
            <h4>{myBoosterState?.description || "-"}</h4>
          </div>
        </li>
      </ul>
    </div>
  );
};

export default MyBooster;
