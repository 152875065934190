// @ts-nocheck
import { useEffect, useState } from "react";

import ZoomInIcon from "@mui/icons-material/ZoomIn";

import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link, useNavigate } from "react-router-dom";
import { STORAGE_KEYS, errorToast, getFromStorage } from "../../helpers";

import {
  useGetMostlySearchedMutation,
  useGetMostlyViewedMutation,
} from "../../service/Home";
import { FeaturedProductsData } from "../../types/General";
import useTranslation from "../../hooks/Translation";
import QuickView from "../../components/QuickView";
import { GoogleTranslateScript } from "../../utils/GoogleTranslateScript";
import { useAppSelector } from "../../hooks/store";
import { getTempToken } from "../../reducers/authSlice";

const Mostlysearched = () => {
  const token = getFromStorage(STORAGE_KEYS.token);
  const tempToken = useAppSelector(getTempToken);
  const language = getFromStorage(STORAGE_KEYS.language);
  const navigate = useNavigate();
  const translation = useTranslation() as any;
  const [open, setOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<FeaturedProductsData>();
  const [selectedImage, setSelectedImage] = useState("");

  const [mostSearched, setMostSearched] = useState([]);
  const [mostViewed, setMostViewed] = useState([]);

  const [getMostSearched] = useGetMostlySearchedMutation();
  const [getMostViewed] = useGetMostlyViewedMutation();
  const [page, setPage] = useState(1);
  const [count, setCount] = useState<number>(0);

  const [viewCount, setViewCount] = useState<number>(0);

  const [translatedSearchProductName, setTranslatedSearchProductName] =
    useState("");
  const [translatedViewedProductName, setTranslatedViewedProductName] =
    useState("");
  const targetLanguage = language === "ar" ? "ar" : "en";

  const handleOpenPopup = (e: any, item: FeaturedProductsData) => {
    e.stopPropagation();
    e.preventDefault();
    setSelectedImage(item?.images[0]);
    setSelectedItem(item);
    setOpen(true);
  };

  const getMostSearchedProducts = async () => {
    try {
      const response = await getMostSearched({
        page: page,
        size: 6,
        categoryId: "",
        sort: "",
        minPrice: 0,
        maxPrice: 10000000000,
      }).unwrap();
      if (response?.statusCode === 200) {
        setMostSearched(response?.data?.products);
        setCount(response?.data?.count);
      } else {
        setMostSearched([]);
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");
    }
  };
  const getMostViewedProducts = async () => {
    try {
      const response = await getMostViewed({
        page: page,
        size: 6,
        categoryId: "",
        sort: "",
        minPrice: 0,
        maxPrice: 10000000000,
      }).unwrap();
      if (response?.statusCode === 200) {
        setMostViewed(response?.data?.products);
        setViewCount(response?.data?.count);
      } else {
        setMostViewed([]);
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");
    }
  };

  // const handleTranslateMostlySeach = async () => {
  //   const productNameArray = mostSearched
  //     ?.map((item) => item?.name)
  //     .filter(Boolean);

  //   if (productNameArray?.length > 0) {
  //     const translatedTexts = await Promise.all(
  //       productNameArray.map(async (productName) => {
  //         const translatedText = await GoogleTranslateScript(
  //           productName,
  //           targetLanguage
  //         );
  //         return translatedText;
  //       })
  //     );
  //     setTranslatedSearchProductName(translatedTexts);
  //   }
  // };

  // const handleTranslateMostlyViewed = async () => {
  //   const productNameArray = mostViewed
  //     ?.map((item) => item?.name)
  //     .filter(Boolean);

  //   if (productNameArray?.length > 0) {
  //     const translatedTexts = await Promise.all(
  //       productNameArray.map(async (productName) => {
  //         const translatedText = await GoogleTranslateScript(
  //           productName,
  //           targetLanguage
  //         );
  //         return translatedText;
  //       })
  //     );
  //     setTranslatedViewedProductName(translatedTexts);
  //   }
  // };

  // useEffect(() => {
  //   handleTranslateMostlySeach();
  // }, [language, mostSearched]);
  // useEffect(() => {
  //   handleTranslateMostlyViewed();
  // }, [language, mostViewed]);

  useEffect(() => {
    if (token || tempToken) {
      getMostSearchedProducts();
      getMostViewedProducts();
    }
  }, [page, tempToken]);

  return (
    <>
      {mostSearched?.length || mostViewed?.length ? (
        <section className="container most_sec">
          {mostSearched?.length ? (
            <div
              className={
                !mostViewed?.length ? "mostsearch wdth_100" : "mostsearch"
              }
            >
              <h3 style={{ fontSize: "15px" }}>
                {translation.Globals.mostly_search}
              </h3>
              <div className="arrival_flex search_flx">
                {mostSearched?.length
                  ? mostSearched?.slice(0, 6)?.map((item, index) => {
                      return (
                        <li key={item?._id}>
                          <Link
                            to={`/product-detail/${item?._id}`}
                            className="prdct_crd"
                          >
                            <figure className="prdct_img">
                              <figure
                                className="zoom_icon"
                                onClick={(e) => handleOpenPopup(e, item)}
                              >
                                <ZoomInIcon />
                              </figure>

                              <LazyLoadImage
                                alt="prdct_img"
                                src={item?.images[0]}
                                effect="blur"
                                height={120}
                                width={145}
                              />
                            </figure>
                            <div className="prdct_dtl">
                              <h3>
                                {/* {" "}
                                {language === "en" ? (
                                  <>
                                    {item?.lang === 1
                                      ? item?.name
                                      : translatedSearchProductName[index] ||
                                        ""}
                                  </>
                                ) : (
                                  <>
                                    {item?.lang === 2
                                      ? item?.name
                                      : translatedSearchProductName[index] ||
                                        ""}
                                  </>
                                )}{" "} */}
                                {item?.name || "-"}
                              </h3>
                            </div>
                          </Link>
                        </li>
                      );
                    })
                  : null}
              </div>
              {count > 6 ? (
                <h6
                  style={{
                    fontSize: "15px",
                    fontWeight: 500,
                    color: "#0101af",
                    cursor: "pointer",
                  }}
                  onClick={() => navigate(`/all-products/${"search"}`)}
                >
                  {translation.Globals.explore_more_products}
                </h6>
              ) : null}
            </div>
          ) : null}

          {mostViewed?.length ? (
            <div
              className={
                !mostSearched?.length
                  ? "mostsearch most_viewed wdth_100"
                  : "mostsearch most_viewed"
              }
            >
              <h3 style={{ fontSize: "15px" }}>
                {translation.Globals.mostly_view}
              </h3>
              <div className="arrival_flex search_flx">
                {mostViewed?.length
                  ? mostViewed?.slice(0, 6)?.map((item, index) => {
                      return (
                        <li key={item?._id}>
                          <Link
                            to={`/product-detail/${item?._id}`}
                            className="prdct_crd"
                          >
                            <figure className="prdct_img">
                              <figure
                                className="zoom_icon"
                                onClick={(e) => handleOpenPopup(e, item)}
                              >
                                <ZoomInIcon />
                              </figure>

                              <LazyLoadImage
                                alt="prdct_img"
                                src={item?.images[0]}
                                effect="blur"
                                height={120}
                                width={145}
                              />
                            </figure>
                            <div className="prdct_dtl">
                              <h3>
                                {/* {" "}
                                {language === "en" ? (
                                  <>
                                    {item?.lang === 1
                                      ? item?.name
                                      : translatedViewedProductName[index] ||
                                        ""}
                                  </>
                                ) : (
                                  <>
                                    {item?.lang === 2
                                      ? item?.name
                                      : translatedViewedProductName[index] ||
                                        ""}
                                  </>
                                )}{" "} */}
                                {item?.name || "-"}
                              </h3>
                            </div>
                          </Link>
                        </li>
                      );
                    })
                  : null}
              </div>
              {viewCount > 6 ? (
                <h6
                  style={{
                    fontSize: "15px",
                    fontWeight: 500,
                    color: "#0101af",
                    cursor: "pointer",
                  }}
                  onClick={() => navigate(`/all-products/${"view"}`)}
                >
                  {translation.Globals.explore_more_products}
                </h6>
              ) : null}
            </div>
          ) : null}

          <QuickView
            open={open}
            setOpen={setOpen}
            item={selectedItem}
            selectedImage={selectedImage}
            setSelectedImage={setSelectedImage}
          />
        </section>
      ) : null}
    </>
  );
};

export default Mostlysearched;
