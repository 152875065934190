import { differenceInSeconds } from "date-fns";
import { useEffect, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const SeasonalOffer1 = ({ offer1 }: any) => {
  const [timeRemaining, setTimeRemaining] = useState({
    hours: "00",
    minutes: "00",
    seconds: "00",
  });

  useEffect(() => {
    const targetDate = new Date(offer1?.valid_till);
    const interval = setInterval(() => {
      const now = new Date();
      const secondsRemaining = differenceInSeconds(targetDate, now);

      if (secondsRemaining <= 0) {
        clearInterval(interval);
        setTimeRemaining({
          hours: "00",
          minutes: "00",
          seconds: "00",
        });
      } else {
        const hours = String(Math.floor(secondsRemaining / 3600)).padStart(
          2,
          "0"
        );
        const minutes = String(
          Math.floor((secondsRemaining % 3600) / 60)
        ).padStart(2, "0");
        const seconds = String(secondsRemaining % 60).padStart(2, "0");

        setTimeRemaining({
          hours,
          minutes,
          seconds,
        });
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [offer1]);
  return (
    <section className="ssnl_offr1 container">
      <div className="ofr1_lft">
        <figure>
          <LazyLoadImage
            src={offer1?.image}
            alt="Offer image"
            effect="blur"
            width={"100%"}
          />
        </figure>
      </div>
      <div className="ofr1_ryt">
        <h2>{offer1?.heading || ""}</h2>
        <p
          dangerouslySetInnerHTML={{
            __html: offer1?.description || "",
          }}
        />
        <ul className="offr_time">
          <li>
            <h6>{timeRemaining.hours}</h6>
          </li>
          <li>
            <h6>{timeRemaining.minutes}</h6>
          </li>
          <li>
            <h6>{timeRemaining.seconds}</h6>
          </li>
        </ul>
      </div>
    </section>
  );
};

export default SeasonalOffer1;
