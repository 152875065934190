import React, { useEffect, useState } from "react";
import { Button, InputField } from "../../components";
import { Box, FormControl, Input, Modal } from "@mui/material";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";

import useAuth from "../../hooks/useAuth";
import { useFormik } from "formik";
import * as Yup from "yup";
import AddIcon from "@mui/icons-material/Add";
import { UploadMedia } from "../../utils/uploadMedia";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import {
  Loader,
  STORAGE_KEYS,
  errorToast,
  getFromStorage,
  successToast,
} from "../../helpers";
import {
  useJustSendOtpMutation,
  useJustVerifyOtpMutation,
  usePostSetProfileMutation,
} from "../../service/Auth";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { CommonBody } from "../../types/General";
import { useAppDispatch } from "../../hooks/store";
import { setCredentials } from "../../reducers/authSlice";
import { generateResponsiveStyle } from "../../utils/AuthModalStyle";
import CloseIcon from "@mui/icons-material/Close";
import OTPInput from "react-otp-input";
import { isString } from "../../utils/validations";
import useTranslation from "../../hooks/Translation";

const otpStyle = {
  width: "50px",
  height: "50px",
  "box-sizing": "border-box",
  margin: "0 auto",
  paddingBottom: 20,
};

type props = {
  state: string;
};

const EditProfile = ({ state }: props) => {
  const language = getFromStorage(STORAGE_KEYS.language);
  const translation = useTranslation() as any;
  const user = useAuth();

  const style = generateResponsiveStyle();
  const dispatch = useAppDispatch();
  const [isPhone, setIsPhone] = useState(false);

  const [otp, setOtp] = useState<string>("");
  const [countDown, setCountDown] = useState<number>(59);
  const [openModal, setOpenModal] = useState(false);
  const [phoneCode, setPhoneCode] = useState("+971");
  const [image, setImage] = useState<string>("");
  const [error, setError] = useState<boolean>(false);
  const [emailVerified, setEmailVerified] = useState(false);
  const [oldNum, setOldNum] = useState<string>("");
  const [oldEmail, setOldEmail] = useState<string>("");
  const [phoneVerified, setPhoneVerified] = useState(false);
  const [oldCode, setOldCode] = useState("+971");
  const [tab, setTab] = useState(1);

  const [updateProfile, { isLoading }] = usePostSetProfileMutation();
  const [sendOtp] = useJustSendOtpMutation();
  const [verifyOtp] = useJustVerifyOtpMutation();

  const handleChangePhone = (phone: any, country: any) => {
    formik.setFieldValue("phone", phone?.replace(country.dialCode, ""));
    // setOldCode(`+${country?.dialCode}`);
    setPhoneCode(`+${country?.dialCode}`);
  };
  const closeModal = () => {
    setOpenModal(false);
  };

  //for resend otp
  const handleResendOtp = async () => {
    let data = {
      ...(isPhone
        ? {
            phone: formik.values.phone,
            countryCode: (phoneCode.includes("+") ? "" : "+") + phoneCode,
          }
        : {
            key: formik.values.email,
          }),
      resend: true,
    };

    try {
      let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;
      const response = await sendOtp(encryptedBody).unwrap();
      if (response?.statusCode === 200) {
        successToast(response?.message || "");
        setCountDown(59);
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");
    }
  };

  // for sending otp
  const handleDataChange = async (val: number) => {
    let data = {
      ...(val === 1
        ? {
            phone: formik.values.phone,
            countryCode: phoneCode,
          }
        : {
            key: formik.values.email,
          }),
    };

    if (
      formik.values?.phone?.length > 6 ||
      /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i.test(
        formik.values.email
      )
    ) {
      try {
        let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;
        const response = await sendOtp(encryptedBody).unwrap();
        if (response?.statusCode === 200) {
          successToast(response?.message || "");
          setOpenModal(true);
        }
      } catch (error: any) {
        errorToast(error?.data?.message || "");
      }
    }
  };

  //for otp verification
  const handleSubmit = async () => {
    if (otp?.length === 4) {
      setError(false);
      const data = {
        ...(!isPhone
          ? { key: formik.values.email, code: otp }
          : {
              key: formik.values.phone,
              countryCode: phoneCode,
              code: otp,
            }),
      };

      try {
        let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;
        const response = await verifyOtp(encryptedBody).unwrap();
        if (response?.statusCode === 200) {
          if (isPhone) {
            setOldNum(formik.values.phone);
            setOldCode(phoneCode);
            setPhoneVerified(true);
          } else {
            setOldEmail(formik.values.email);
            setEmailVerified(true);
          }
          setOpenModal(false);
          setOtp("");
          successToast(response?.message || "");
        }
      } catch (error: any) {
        if (error?.data?.message) {
          errorToast(error?.data?.message || "");
        }
      }
    } else {
      setError(true);
    }
  };

  // for update profile
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: user?.email || "",
      fullName: user?.fullName || "",
      phone: user?.phone || "",
    },

    validationSchema: Yup.object({
      email: Yup.string()
        .required(translation.validations.email_required)
        .matches(
          /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
          translation.validations.valid_email
        ),
      fullName: Yup.string()
        .required(translation.validations.required_field)
        .min(3, translation.validations.min_three)
        .max(40, translation.validations.max_forty),

      phone: Yup.string()
        .required(translation.validations.required_field)
        .min(6, translation.validations.phone_min)
        .max(16, translation.validations.phone_max),
    }),
    onSubmit: async (values) => {
      formik.setSubmitting(true);

      let data = {
        fullName: formik.values.fullName,

        email: formik.values.email,
        phone: formik.values.phone,
        countryCode: phoneCode,
        image: image,
        isProfileComplete: true,
        ...(formik.values.email !== user?.email
          ? {
              sendPassword: true,
            }
          : {}),
      };

      const newNumber = formik.values?.phone !== oldNum;
      const newEmail = formik.values?.email !== oldEmail;

      if (newNumber || phoneCode !== oldCode || !phoneVerified) {
        errorToast(translation.toast_messages.verify_phone);
      } else if (newEmail || !emailVerified) {
        errorToast(translation.toast_messages.verify_email);
      } else {
        try {
          let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;
          const response = await updateProfile(encryptedBody).unwrap();

          if (response?.statusCode === 200) {
            successToast(
              response?.message || translation.toast_messages.profile_updated
            );
            const token = getFromStorage(STORAGE_KEYS.token);
            dispatch(
              setCredentials({
                token: JSON.parse(`${token}`),
                user: response?.data || null,
              })
            );
          } else {
            errorToast(response?.message || "");
          }
        } catch (error: any) {
          errorToast(error?.data?.message || "");
          console.log(error);
        }
      }
    },
  });

  useEffect(() => {
    setPhoneCode(user?.countryCode || "+971");
    setOldCode(user?.countryCode || "+971");
    setOldEmail(user?.email || "");
    setImage(user?.image || "");
    setEmailVerified(user ? user.isEmailVerify : false);
    setPhoneVerified(user ? user.isPhoneVerify : false);
    setOldNum(user ? user?.phone : "+971");
  }, [user]);

  const handleImageUpload = async (file: any) => {
    if (file?.type.includes("image")) {
      try {
        const res = await UploadMedia(file);
        if (res?.statusCode === 200) {
          setImage(res?.data);
        }
      } catch (error: any) {
        console.log(error);
      }
    } else {
      setImage("");
      errorToast(translation.validations.images_allowed);
    }
  };

  useEffect(() => {
    if (openModal) {
      if (countDown > 0) {
        setTimeout(() => {
          setCountDown(countDown - 1);
        }, 1000);
      } else {
        setCountDown(0);
      }
    }
  }, [countDown, openModal]);

  useEffect(() => {
    if (state === "seller_details") {
      setTab(2);
    }
  }, []);

  return (
    <div className="main">
      <Loader isLoad={isLoading} />
      <h2 style={{ textAlign: "center" }}>{translation.Globals.profile}</h2>

      <div className="tabs listing_tabs">
        <h3
          style={{ width: "50%", fontSize: "" }}
          className={tab === 1 ? "active" : ""}
          onClick={() => {
            setTab(1);
          }}
        >
          {translation.Globals.user_details}
        </h3>
        <h3
          style={{ width: "50%" }}
          className={tab === 2 ? "active" : ""}
          onClick={() => {
            setTab(2);
          }}
        >
          {translation.Globals.business_details}
        </h3>
      </div>
      {tab === 1 ? (
        <>
          <div className="image">
            <label htmlFor="icon-button-file">
              <Input
                sx={{ display: "none" }}
                id="icon-button-file"
                type="file"
                inputProps={{
                  accept: "image/png,image/jpeg, image/jpg",
                }}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  if (event.target.files && event.target.files[0]) {
                    setImage(URL.createObjectURL(event.target.files[0]));
                    handleImageUpload(event.target.files[0]);
                  }
                }}
              />

              <figure>
                {!image ? (
                  <LazyLoadImage
                    className="img_style2"
                    alt="empty_user"
                    src={"/static/images/empty_user.png"}
                    effect="blur"
                    height={"100%"}
                    width={"100%"}
                  />
                ) : (
                  <LazyLoadImage
                    className="img_style"
                    alt="user"
                    src={image}
                    effect="blur"
                    height={"100%"}
                    width={"100%"}
                  />
                )}
              </figure>
              <AddIcon />
            </label>
          </div>
          <form onSubmit={formik.handleSubmit}>
            <div className="form_control">
              <h3>{translation.Globals.full_name}</h3>
              <InputField
                placeholder={translation.Globals.full_name}
                name="fullName"
                id="fullName"
                inputProps={{ maxLength: 40 }}
                value={formik.values.fullName}
                onChange={(val) => {
                  if (
                    val.target.value === " " ||
                    val.target.value.includes(".")
                  ) {
                  } else if (isString(val.target.value)) {
                    formik.handleChange(val);
                  }
                }}
                onBlur={formik.handleBlur}
                helperText={formik.touched.fullName && formik.errors.fullName}
              />
            </div>
            <div className="form_control">
              <h3>{translation.Auth.Login.email_id}</h3>
              <div className="vrfctn_dv2">
                <InputField
                  placeholder={translation.Auth.Login.email_id}
                  name="email"
                  id="edit_email"
                  inputProps={{ maxLength: 80 }}
                  value={formik.values.email}
                  onChange={(val) => {
                    if (val.target.value === " " || val.target.value === ".") {
                    } else {
                      formik.handleChange(val);
                    }
                  }}
                  onBlur={formik.handleBlur}
                  helperText={formik.touched.email && formik.errors.email}
                />

                {formik.values?.email?.length > 0 &&
                formik.values?.email === oldEmail &&
                emailVerified ? (
                  <div>
                    <figure
                      className="verified"
                      style={{
                        right: language === "ar" ? "unset" : "15px",
                        left: language === "ar" ? "15px" : "unset",
                      }}
                    >
                      <LazyLoadImage
                        src={"/static/images/phonetick.png"}
                        alt="phonetick"
                        effect="blur"
                        width={"100%"}
                      />
                    </figure>
                  </div>
                ) : (
                  <div>
                    {user && (
                      <div>
                        <button
                          style={{
                            right: language === "ar" ? "unset" : "15px",
                            left: language === "ar" ? "15px" : "unset",
                          }}
                          className={
                            !/^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i.test(
                              formik.values.email
                            )
                              ? "vrfctn_btn2"
                              : "vrfctn_btn"
                          }
                          type="button"
                          onClick={() => {
                            setIsPhone(false);
                            handleDataChange(0);
                          }}
                        >
                          {formik.values?.email?.length === 0
                            ? ""
                            : !/^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i.test(
                                formik.values.email
                              )
                            ? translation.profile.invalid_email
                            : translation.profile.verify}
                        </button>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
            <div className="form_control">
              <h3>{translation.Globals.phone_number}</h3>
              <div className="vrfctn_dv">
                <PhoneInput
                  country={"in"}
                  value={phoneCode + formik.values.phone}
                  onChange={(phone: any, country: any) =>
                    handleChangePhone(phone, country)
                  }
                  onBlur={formik.handleBlur}
                  placeholder="0 (000) 000-000"
                  enableSearch={true}
                  inputStyle={{
                    width: "100%",
                    backgroundColor: "#EBEBEB",
                  }}
                />
                {formik.values?.phone?.length > 0 &&
                formik.values?.phone === oldNum &&
                phoneCode === oldCode &&
                phoneVerified ? (
                  <div>
                    <figure
                      className="verified"
                      style={{
                        right: language === "ar" ? "unset" : "15px",
                        left: language === "ar" ? "15px" : "unset",
                      }}
                    >
                      <LazyLoadImage
                        src={"/static/images/phonetick.png"}
                        alt="phonetick"
                        effect="blur"
                        width={"100%"}
                      />
                    </figure>
                  </div>
                ) : (
                  <div>
                    {user && (
                      <div>
                        <button
                          style={{
                            right: language === "ar" ? "unset" : "15px",
                            left: language === "ar" ? "15px" : "unset",
                          }}
                          className={
                            formik.values?.phone?.length === 0
                              ? "vrfctn_btn2"
                              : formik.values?.phone?.length < 6
                              ? "vrfctn_btn2"
                              : "vrfctn_btn"
                          }
                          type="button"
                          onClick={() => {
                            setIsPhone(true);
                            handleDataChange(1);
                          }}
                        >
                          {formik.values?.phone?.length === 0
                            ? ""
                            : formik.values?.phone?.length < 6
                            ? translation.profile.invalid_number
                            : translation.profile.verify}
                        </button>
                      </div>
                    )}
                  </div>
                )}
              </div>
              {formik.touched.phone && formik.errors.phone ? (
                <h6 className="err_msg">
                  {formik.touched.phone && formik.errors.phone}
                </h6>
              ) : (
                ""
              )}
              <div style={{ marginBottom: 10 }} />
            </div>

            <div className="btn">
              <Button value={translation.Globals.update_detail} />
            </div>
          </form>
        </>
      ) : (
        <>
          <p className="busnss_dtl_text">
            {translation.Globals.status}:{"  "}
            {user?.sellerForm?.status === 1
              ? translation.Globals.pending
              : user?.sellerForm?.status === 2
              ? translation.Globals.accepted
              : user?.sellerForm?.status === 3
              ? translation.Globals.rejected
              : ""}
          </p>
          <div className="image">
            <figure>
              {!user?.sellerForm?.logoImage?.length ? (
                <LazyLoadImage
                  className="img_style2"
                  alt="empty_user"
                  src={"/static/images/empty_user.png"}
                  effect="blur"
                  height={"100%"}
                  width={"100%"}
                />
              ) : (
                <LazyLoadImage
                  className="img_style"
                  alt="logoImage"
                  src={user?.sellerForm?.logoImage}
                  effect="blur"
                  height={"100%"}
                  width={"100%"}
                />
              )}
            </figure>
          </div>

          <form onSubmit={formik.handleSubmit}>
            <div className="form_control">
              <h3>{translation.Globals.business_name}</h3>
              <InputField
                placeholder={translation.Globals.business_name}
                value={user?.sellerForm?.businessName || "--"}
                disabled
              />
              <h3>{translation.Globals.mobile_no}</h3>
              <InputField
                placeholder={translation.Globals.mobile_no}
                value={
                  user?.sellerForm
                    ? `${user.sellerForm.countryCode || ""}${
                        user.sellerForm.phone || ""
                      }`
                    : "--"
                }
                disabled
              />

              <h3>{translation.Globals.business_landline}</h3>
              <InputField
                placeholder={translation.Globals.business_landline}
                value={user?.sellerForm?.landline || "--"}
                disabled
              />
              <h3>{translation.Globals.business_email}</h3>
              <InputField
                placeholder={translation.Globals.business_email}
                value={user?.sellerForm?.email || "--"}
                disabled
              />
              <h3>{translation.Globals.address}</h3>
              <InputField
                placeholder={translation.Globals.business_email}
                value={user?.sellerForm?.address || "--"}
                disabled
              />
              <h3>{translation.Globals.license_no}</h3>
              <InputField
                placeholder={translation.Globals.license_no}
                value={user?.sellerForm?.licenseNo || "--"}
                disabled
              />
            </div>
          </form>
        </>
      )}

      <Modal
        open={openModal}
        onClose={closeModal}
        hideBackdrop
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="AuthWrap">
            <div className="cross">
              <CloseIcon onClick={() => setOpenModal(false)} />
            </div>
            <h2>{translation.Auth.otp_verification.verification}</h2>

            {isPhone ? (
              <p>
                {translation.Auth.otp_verification.phone_code}{" "}
                <span>
                  {(phoneCode.includes("+") ? "" : "+") +
                    phoneCode +
                    formik.values.phone || ""}
                </span>
              </p>
            ) : (
              <p>
                {translation.Auth.otp_verification.email_code}{" "}
                <span>{formik.values.email || ""}</span>
              </p>
            )}

            <FormControl className="opt_fields" sx={{ width: "100%" }}>
              <OTPInput
                value={otp}
                onChange={setOtp}
                numInputs={4}
                renderInput={(props) => <input {...props} />}
                inputStyle={otpStyle}
                inputType="tel"
                shouldAutoFocus
              />
              <br />
              {error && otp.length !== 4 ? (
                <h6 className="err_msg" style={{ textAlign: "center" }}>
                  {translation.validations.required_field}
                </h6>
              ) : (
                ""
              )}
            </FormControl>

            <Button value={translation.Globals.next} onClick={handleSubmit} />

            {countDown === 0 ? (
              <div className="resend">
                <h4 onClick={handleResendOtp}>
                  {" "}
                  {translation.Auth.otp_verification.resend}
                </h4>
              </div>
            ) : (
              <div className="continue" style={{ marginBottom: "10px" }}>
                <h5>
                  {translation.Auth.otp_verification.code_expire} 00 :{" "}
                  {countDown < 10 ? `0${countDown}` : countDown}
                </h5>
              </div>
            )}
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default EditProfile;
