import React, { lazy, Suspense, useEffect, useState } from "react";
import { Helmet } from "react-helmet";

import Layout from "../../layout";
// import {
//   AdsSection,
//   Arrivals,
//   Banners,
//   Categories,
//   DownloadApp,
//   Featureproduct,
//   Mostlysearched,
//   Officialstores,
//   TopManufacturers,
//   WeeklyDeals,
// } from "../../features";

import "./Home.scss";
import {
  useGetNewArrivalsMutation,
  useLazyDashboardBannerQuery,
  useLazyDashboardFeaturedProductsQuery,
  useLazyDashboardRecentProductsQuery,
  useLazyDashboardRecommendedProductsQuery,
} from "../../service/Home";
import {
  END_POINTS,
  STORAGE_KEYS,
  WORDPRESS_URL,
  errorToast,
  getFromStorage,
} from "../../helpers";
import {
  FeaturedProductsData,
  ProductBannerData,
  RecentProduct,
  RecommendedProductsData,
} from "../../types/General";

import { getTempToken } from "../../reducers/authSlice";
import { useAppSelector } from "../../hooks/store";

// import PopularProducts from "../../features/home/PopularProducts";
// import RecommendedProducts from "../../features/home/RecommendedProducts";
import { Skeleton } from "@mui/material";
import {
  WORDPRESS_PASSWORD,
  WORDPRESS_USERNAME,
} from "../../helpers/constants/urls";

// Lazy-loaded components
const Banners = lazy(() => import("../../features/home/Banners"));
const RecommendedProducts = lazy(
  () => import("../../features/home/RecommendedProducts")
);
const Arrivals = lazy(() => import("../../features/home/Arrivals"));
const Featureproduct = lazy(
  () => import("../../features/home/featuredproduct")
);
const AdsSection = lazy(() => import("../../features/home/adsSection"));
const Mostlysearched = lazy(() => import("../../features/home/mostlysearch"));
const PopularProducts = lazy(
  () => import("../../features/home/PopularProducts")
);
const Officialstores = lazy(() => import("../../features/home/officialstores"));
const TopManufacturers = lazy(
  () => import("../../features/home/TopManufacturers")
);
const DownloadApp = lazy(() => import("../../features/home/DownloadApp"));
const WeeklyDeals = lazy(() => import("../../features/home/WeeklyDeals"));
const Categories = lazy(() => import("../../features/home/Categories"));

const HomePage = () => {
  const token = getFromStorage(STORAGE_KEYS.token);
  const tempToken = useAppSelector(getTempToken);
  const [getNewArrivals] = useGetNewArrivalsMutation();
  const [getBanners] = useLazyDashboardBannerQuery();
  const [getFeaturedProducts] = useLazyDashboardFeaturedProductsQuery();
  const [getRecentProducts] = useLazyDashboardRecentProductsQuery();
  const [getRecommendedProducts] = useLazyDashboardRecommendedProductsQuery();
  const [weeklyDeals, setWeeklyDeals] = useState<any>(null);
  const [topManufacturers, setTopManufacturers] = useState<any>(null);

  const [productBannerData, setProductBannerData] = useState<
    ProductBannerData[]
  >([]);
  const [featuredProducts, setFeaturedProducts] = useState<
    FeaturedProductsData[]
  >([]);
  const [recentProductsData, setRecentProductsData] = useState<RecentProduct[]>(
    []
  );
  const [recommendedProducts, setRecommendedProducts] = useState<
    RecommendedProductsData[]
  >([]);
  const [newProducts, setNewProducts] = useState([]);
  const [newCategories, setNewCategories] = useState([]);
  const [recommendedLoading, setRecommendedLoading] = useState(true);
  const [bannerLoading, setBannerLoading] = useState(true);
  const [arrivalLoading, setArrivalLoading] = useState(true);
  const [weeklyDealsLoading, setWeeklyDealsLoading] = useState(false);
  const [loading, setLoading] = useState(true);

  const fetchBanners = async () => {
    try {
      setBannerLoading(true);
      //@ts-ignore
      const response = await getBanners({}).unwrap();
      if (response?.statusCode === 200) {
        setBannerLoading(false);
        setProductBannerData(
          // @ts-ignore
          response?.data?.productBanner || []
        );
      } else {
        setBannerLoading(false);
        setProductBannerData([]);
      }
      setBannerLoading(false);
    } catch (error: any) {
      setBannerLoading(false);
      errorToast(error?.data?.message || "");
    }
  };

  const fetchRecommended = async () => {
    try {
      setRecommendedLoading(true);
      //@ts-ignore
      const response = await getRecommendedProducts({}).unwrap();
      if (response?.statusCode === 200) {
        setRecommendedLoading(false);
        // @ts-ignore
        setRecommendedProducts(response?.data || []);
      } else {
        setRecommendedLoading(false);
        setRecommendedProducts([]);
      }
      setRecommendedLoading(false);
    } catch (error: any) {
      setRecommendedLoading(false);
      errorToast(error?.data?.message || "");
    }
  };

  const fetchFeatured = async () => {
    try {
      setLoading(true);
      //@ts-ignore
      const response = await getFeaturedProducts({}).unwrap();
      if (response?.statusCode === 200) {
        setLoading(false);
        // @ts-ignore
        setFeaturedProducts(response?.data || []);
      } else {
        setLoading(false);
        setFeaturedProducts([]);
      }
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      errorToast(error?.data?.message || "");
    }
  };

  const fetchRecent = async () => {
    try {
      setLoading(true);
      //@ts-ignore
      const response = await getRecentProducts({}).unwrap();
      if (response?.statusCode === 200) {
        setLoading(false);
        // @ts-ignore
        setRecentProductsData(response?.data || []);
      } else {
        setLoading(false);
        setRecentProductsData([]);
      }
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      errorToast(error?.data?.message || "");
    }
  };

  const fetchNewArrival = async () => {
    try {
      setArrivalLoading(true);
      //@ts-ignore
      const response = await getNewArrivals({}).unwrap();
      if (response?.statusCode === 200) {
        setArrivalLoading(false);
        setNewCategories(response?.data?.newCategories || []);
        setNewProducts(response?.data?.products || []);
      } else {
        setArrivalLoading(false);
        setNewCategories([]);
        setNewProducts([]);
      }
      setArrivalLoading(false);
    } catch (error: any) {
      setArrivalLoading(false);
      errorToast(error?.data?.message || "");
    }
  };

  const getWeeklyDeals = async () => {
    const url = `${WORDPRESS_URL}${END_POINTS.seasonalOffers}/233`;

    try {
      setWeeklyDealsLoading(true);
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Basic ${btoa(
            `${WORDPRESS_USERNAME}:${WORDPRESS_PASSWORD}`
          )}`,
        },
      });
      if (response?.ok) {
        setWeeklyDealsLoading(false);
        const responseData = await response.json();
        if (responseData?.status === 200) {
          setWeeklyDeals(responseData?.data?.weekly_deal);
          setTopManufacturers(responseData?.data?.top_manufacture);
        }
      } else {
        setTopManufacturers(null);
        setWeeklyDeals(null);
        setWeeklyDealsLoading(false);
      }
    } catch (error) {
      console.error("Error:", error);
      setWeeklyDealsLoading(false);
      throw error;
    }
  };

  useEffect(() => {
    getWeeklyDeals();
  }, []);

  useEffect(() => {
    if (token || tempToken) {
      setLoading(true);
      fetchBanners();
      setLoading(false);
      fetchRecommended();
      fetchNewArrival();
      fetchFeatured();
      fetchRecent();
    }
  }, [tempToken]);

  const CategoryBanner = () => {
    return (
      <ul>
        <li>
          <a>
            <figure>
              <Skeleton
                animation="wave"
                variant="rounded"
                width={"100%"}
                height={"100%"}
                style={{ borderRadius: 100 }}
              />
            </figure>
          </a>
        </li>
        <Skeleton
          animation="wave"
          variant="rounded"
          width={"100%"}
          height={"10px"}
          style={{ marginTop: 10 }}
        />
      </ul>
    );
  };

  return (
    <Layout>
      <Helmet>
        <title>
          Dubai Shopping Offers & Deals | Online Shopping UAE - Krafo
        </title>
        <meta
          name="description"
          content="Discover the best online shopping experience in UAE at Krafo. Find top wholesale suppliers in UAE, amazing Dubai shopping offers, and online sales in Abu Dhabi."
        />

        <meta
          property="og:title"
          content="Dubai Shopping Offers & Deals | Online Shopping UAE - Krafo"
        />
        <meta
          property="og:description"
          content="Discover the best online shopping experience in UAE at Krafo. Find top wholesale suppliers in UAE, amazing Dubai shopping offers, and online sales in Abu Dhabi."
        />

        <meta
          name="twitter:title"
          content="Dubai Shopping Offers & Deals | Online Shopping UAE - Krafo"
        />
        <meta
          name="twitter:description"
          content="Discover the best online shopping experience in UAE at Krafo. Find top wholesale suppliers in UAE, amazing Dubai shopping offers, and online sales in Abu Dhabi."
        />
      </Helmet>
      <div style={{ paddingTop: "30px", paddingBottom: 0 }}>
        <Banners
          bannerLoading={bannerLoading}
          productBannerData={productBannerData}
        />

        {loading ? (
          <section className="hm_categories container prodcts_sc cat_shim">
            {Array.from({ length: 9 }, (_, index) => (
              <CategoryBanner key={index} />
            ))}
          </section>
        ) : (
          <Categories />
        )}
        <Suspense fallback={null}>
          <RecommendedProducts
            recommendedProducts={recommendedProducts}
            loading={recommendedLoading}
          />

          <Arrivals
            arrivalLoading={arrivalLoading}
            productBannerData={productBannerData}
            newCategories={newCategories}
            newProducts={newProducts}
          />
        </Suspense>

        {!loading ? (
          <div>
            <Suspense fallback={null}>
              <Featureproduct featuredProducts={featuredProducts} />
              <AdsSection productBannerData={productBannerData} />{" "}
              <Mostlysearched />{" "}
              {weeklyDeals?.weekly_data?.length ? (
                <WeeklyDeals weeklyDeals={weeklyDeals} />
              ) : null}
              {recentProductsData?.length === 0 ? null : (
                <PopularProducts recentProductsData={recentProductsData} />
              )}
              <Officialstores />
              {topManufacturers?.top_manufacture_data?.length ? (
                <TopManufacturers topManufacturers={topManufacturers} />
              ) : null}
            </Suspense>
          </div>
        ) : null}
      </div>
      <Suspense fallback={null}>
        <DownloadApp />
      </Suspense>
    </Layout>
  );
};

export default HomePage;
