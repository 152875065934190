import { Box, Button, Modal } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React, { Dispatch, SetStateAction } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

type props = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  images: string[] | undefined;
};

const DocumentViewer = ({ open, setOpen, images }: props) => {
  const handleClose = () => {
    setOpen(false);
  };

  const handleBackdropClick = (event: any) => {
    if (event.target.classList.contains("modalBox_body")) {
      setOpen(false);
    }
  };

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 8000,
  };

  return (
    <Modal
      className="modal gallery_modal"
      open={open}
      onClose={handleClose}
      hideBackdrop={false} // Add this line
      onClick={handleBackdropClick}
    >
      <Box className="modalBox_body">
        <Box>
          <Button className="close_btn" onClick={() => setOpen(false)}>
            <CloseIcon />
          </Button>

          <Slider className="gallery_swiper" {...settings}>
            {images?.length
              ? images?.map((item, i) => (
                <div key={i}>
                  <figure>
                    <LazyLoadImage
                      alt="product_image"
                      src={item || ""}
                      effect="blur"
                      height={"100%"}
                      width={"100%"}
                    />
                  </figure>
                </div>
              ))
              : null}
          </Slider>
        </Box>
      </Box>
      {/* </Fade> */}
    </Modal>
  );
};

export default DocumentViewer;
