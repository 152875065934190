import { TextField } from "@mui/material";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { usePostSubscribeNewsLetterMutation } from "../../service/Home";
import { CommonBody } from "../../types/General";
import {
  Loader,
  STORAGE_KEYS,
  errorToast,
  getFromStorage,
  successToast,
} from "../../helpers";

import { useFormik } from "formik";
import * as Yup from "yup";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import useTranslation from "../../hooks/Translation";
import useCategory from "../../hooks/useCategory";
import useAuth from "../../hooks/useAuth";
import { useAppDispatch } from "../../hooks/store";

import { useLazyGetProfileQuery } from "../../service/Auth";
import { setCredentials } from "../../reducers/authSlice";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { format } from "date-fns";

const Footer = () => {
  const navigate = useNavigate();
  const user = useAuth();
  const dispatch = useAppDispatch();
  const categories = useCategory();
  const language = getFromStorage(STORAGE_KEYS.language);
  const translation = useTranslation() as any;
  const [loading, setLoading] = useState(false);

  const [getProfile] = useLazyGetProfileQuery();

  const [subscribeNewLetter, { isLoading }] =
    usePostSubscribeNewsLetterMutation();

  const getUserDetails = async () => {
    const token = getFromStorage(STORAGE_KEYS.token);

    setLoading(true);
    try {
      const result = await getProfile({}).unwrap();
      if (result?.statusCode === 200) {
        setLoading(false);
        dispatch(
          setCredentials({
            token: JSON.parse(`${token}`),
            user: result?.data || null,
          })
        );
      }
      return result;
    } catch (error) {
      setLoading(false);
      console.log(error);
      return false;
    }
  };

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required(translation.validations.email_required)
        .matches(
          /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
          translation.validations.valid_email
        ),
    }),
    onSubmit: async () => {
      formik.setSubmitting(true);
      let data = {
        email: formik.values.email,
      };

      try {
        let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;
        const response = await subscribeNewLetter(encryptedBody).unwrap();
        if (response?.statusCode === 200) {
          successToast(response?.message || "");
          formik.setFieldValue("email", "");
        }
      } catch (error: any) {
        formik.setFieldValue("email", "");
        errorToast(error?.data?.message || "");
        console.log(error);
      }
    },
  });

  const handleSellWithUs = async () => {
    const response = (await getUserDetails()) as any;
    if (response?.data?.role === "ADMIN") {
      navigate("/choose-category");
    } else if (
      !response?.data?.sellFormExists ||
      response?.data?.sellerForm?.status === 3
    ) {
      // setOpen(true);
      navigate("/sell");
    } else if (response?.data?.sellerForm?.status === 1) {
      navigate("/profile", { state: "seller_details" });
      errorToast(translation.Globals.not_approved);
    } else {
      navigate("/choose-category");
    }
  };

  return (
    <>
      <footer className="ftr">
        <Loader isLoad={isLoading} />
        <div className="ftr_top">
          <ul className="container mn_ftr">
            <li>
              <div
                className="logo_dv"
                style={{ width: "30%", alignItems: "flex-start" }}
              >
                <figure className="logo_img">
                  <LazyLoadImage
                    className="bnr_img_style"
                    src={"/static/images/krafo_logo.png"}
                    alt="krafo_logo"
                    effect="blur"
                    height={60}
                    width={60}
                  />
                </figure>
                <h6>{translation.Globals.wholesale || ""}</h6>
              </div>
              <p>{translation.footer.text || ""}</p>
            </li>
            <li>
              <h4>{translation.Globals.categories}</h4>
              {categories?.length
                ? categories?.slice(0, 6)?.map((item) => {
                    return (
                      <Link
                        key={item?._id}
                        to={{
                          pathname: `/products/${item?._id}`,
                          search: `?name_ar=${encodeURIComponent(
                            item?.name_ar || ""
                          )}&name_en=${encodeURIComponent(
                            item?.name || ""
                          )}&subCat=${""}&sort=${""}&minPrice=${0}&maxPrice=${1000000000}&page=${1}`,
                        }}
                      >
                        {language === "ar"
                          ? item?.name_ar || ""
                          : item?.name || ""}
                      </Link>
                    );
                  })
                : null}{" "}
            </li>
            <li className="ftr_head">
              <h4 style={{ color: "#1d1d1d" }}>!</h4>
              {categories?.length
                ? categories
                    ?.slice(
                      6,
                      categories?.length > 12 ? 12 : categories?.length
                    )
                    ?.map((item) => {
                      return (
                        <Link
                          key={item?._id}
                          to={{
                            pathname: `/products/${item?._id}`,
                            search: `?name_ar=${encodeURIComponent(
                              item?.name_ar || ""
                            )}&name_en=${encodeURIComponent(
                              item?.name || ""
                            )}&subCat=${""}&sort=${""}&minPrice=${0}&maxPrice=${1000000000}&page=${1}`,
                          }}
                        >
                          {language === "ar"
                            ? item?.name_ar || ""
                            : item?.name || ""}
                        </Link>
                      );
                    })
                : null}{" "}
              {categories?.length > 12 ? (
                <Link className="see_more" to={`/all-products/${"explore"}`}>
                  See more...
                </Link>
              ) : null}
            </li>
            <li>
              <h4>{translation.footer.support}</h4>
              <h6 onClick={() => navigate("/contact-us")}>
                {translation.footer.contact_us}
              </h6>
              <h6 onClick={() => navigate("/faqs")}>
                {translation.footer.faq}
              </h6>
              <h6 onClick={() => navigate("/blog")}>
                {translation.Globals.blog}
              </h6>
              <h6 onClick={() => navigate("/about-us")}>
                {translation.footer.about_us}
              </h6>
              <h6 onClick={() => navigate("/privacy-policy")}>
                {translation.footer.privacy}
              </h6>
              <h6 onClick={() => navigate("/terms-and-conditions")}>
                {translation.footer.terms_conditions}
              </h6>
              <h6 onClick={() => navigate("/consumer-rights")}>
                {translation.footer.consumer_rights}
              </h6>
              <h6 onClick={() => navigate("/seasonal-offers")}>
                {translation.Globals.seasonal_offers}
              </h6>
              {user && user?.isProfileComplete ? (
                <h6 onClick={handleSellWithUs}>
                  {translation.footer.sell_with_us}
                </h6>
              ) : null}
            </li>
            <li>
              <h4> {translation.footer.follow_us}</h4>
              <div className="scl_icns">
                <a
                  href="https://www.facebook.com/krafoportal/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <figure>
                    <LazyLoadImage
                      className="bnr_img_style"
                      src={"/static/images/facebook.png"}
                      alt="facebook"
                      effect="blur"
                      height={14}
                      width={14}
                    />
                  </figure>
                </a>

                <a
                  href="https://www.instagram.com/krafo_portal/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <figure>
                    <LazyLoadImage
                      className="bnr_img_style"
                      src={"/static/images/instagram.png"}
                      alt="instagram"
                      effect="blur"
                      height={14}
                      width={14}
                    />
                  </figure>
                </a>
                <a
                  href="https://x.com/KRAFODXB"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <figure>
                    <LazyLoadImage
                      className="bnr_img_style"
                      src={"/static/images/twitter.png"}
                      alt="twitter"
                      effect="blur"
                      height={14}
                      width={14}
                    />
                  </figure>
                </a>

                <a
                  href="https://www.linkedin.com/company/krafo/?viewAsMember=true"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <figure>
                    <LazyLoadImage
                      className="bnr_img_style"
                      src={"/static/images/linkedin.png"}
                      alt="linkedin"
                      effect="blur"
                      height={14}
                      width={14}
                    />
                  </figure>
                </a>
                <a
                  href="https://www.youtube.com/@krafodxb"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <figure>
                    <LazyLoadImage
                      className="bnr_img_style"
                      src={"/static/images/youtube.png"}
                      alt="youtube"
                      effect="blur"
                      height={14}
                      width={14}
                    />
                  </figure>
                </a>
                <a
                  href="https://snapchat.com/t/T4Qb7a0x"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <figure>
                    <LazyLoadImage
                      className="bnr_img_style"
                      src={"/static/images/snapchat.png"}
                      alt="snapchat"
                      effect="blur"
                      height={14}
                      width={14}
                    />
                  </figure>
                </a>
                <a
                  href="https://vt.tiktok.com/ZSYGEkDEh/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <figure>
                    <LazyLoadImage
                      className="bnr_img_style"
                      src={"/static/images/tik-tok.png"}
                      alt="tik-tok"
                      effect="blur"
                      height={14}
                      width={14}
                    />
                  </figure>
                </a>
              </div>
              <div className="subscribe_ftr">
                <h4>{translation.footer.subscribe}</h4>
                <form onSubmit={formik.handleSubmit}>
                  <div className="sub_btn">
                    <TextField
                      name="email"
                      id="footer_email"
                      className="ftr_txtfld "
                      hiddenLabel
                      placeholder={translation.footer.enter_email}
                      fullWidth
                      variant="outlined"
                      onChange={(val: { target: { value: string } }) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else {
                          formik.handleChange(val);
                        }
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.email}
                      helperText={formik.touched.email && formik.errors.email}
                    />

                    <button
                      style={{
                        borderRadius:
                          language === "ar" ? "4px 0 0 4px" : "0 4px 4px 0",
                      }}
                      type="submit"
                    >
                      {translation.footer.subscribe}
                    </button>
                  </div>
                </form>
              </div>
            </li>
          </ul>
        </div>
        <div className="ftr_btm">
          <p>
            © {translation.footer.krafo} {format(new Date(), "yyyy")},{" "}
            {translation.footer.rights_reserved}
          </p>
        </div>
      </footer>
    </>
  );
};

export default Footer;
