import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import {
  END_POINTS,
  errorToast,
  getFromStorage,
  setToStorage,
  STORAGE_KEYS,
  successToast,
  WORDPRESS_URL,
} from "../../helpers";
import {
  WORDPRESS_PASSWORD,
  WORDPRESS_USERNAME,
} from "../../helpers/constants/urls";
import Layout from "../../layout";
import "./Sell.scss";

////
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Input,
  Modal,
  Skeleton,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
import { useFormik } from "formik";
import * as Yup from "yup";
import AddIcon from "@mui/icons-material/Add";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";

import OTPInput from "react-otp-input";
import useAuth from "../../hooks/useAuth";
import { useEffect, useState } from "react";
import useTranslation from "../../hooks/Translation";
import { useAppDispatch } from "../../hooks/store";
import { generateResponsiveStyle } from "../../utils/AuthModalStyle";
import {
  useLazyGetProfileQuery,
  usePostSellerFormMutation,
  usePostSignUpMutation,
  useSellerSendOtpMutation,
  useSellerVerifyOtpMutation,
} from "../../service/Auth";
import { UploadMedia } from "../../utils/uploadMedia";
import { setCredentials, temporaryToken } from "../../reducers/authSlice";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { CommonBody } from "../../types/General";
import { Button, InputField } from "../../components";
import { isNumber, isString } from "../../utils/validations";
import { useNavigate } from "react-router-dom";

const otpStyle = {
  width: "50px",
  height: "50px",
  "box-sizing": "border-box",
  margin: "0 auto",
  paddingBottom: 20,
};
/////

const Sell = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>(null);

  /////
  const user = useAuth();

  const translation = useTranslation() as any;
  const dispatch = useAppDispatch();
  const style = generateResponsiveStyle();
  const language = getFromStorage(STORAGE_KEYS.language);

  const [image, setImage] = useState("");
  const [phoneCode, setPhoneCode] = useState("+971");

  const [error, setError] = useState(false);
  const [checked, setChecked] = useState(false);
  const [countDown, setCountDown] = useState<number>(59);
  const [verificationPopup, setVerificationPopup] = useState(false);
  const [otp, setOtp] = useState<string>("");
  const [isPhone, setIsPhone] = useState(false);
  const [newEmail, setNewEmail] = useState(user?.email || "");
  const [newPhone, setNewPhone] = useState(user?.phone || "");
  const [newCountryCode, setNewCountryCode] = useState(user?.countryCode || "");
  const [emailVerified, setEmailVerified] = useState(
    user?.isEmailVerify ? true : false
  );

  const [phoneVerified, setPhoneVerified] = useState(
    user?.isPhoneVerify ? true : false
  );

  const [postSeller] = usePostSellerFormMutation();
  const [getProfile] = useLazyGetProfileQuery();
  const [sendOtp] = useSellerSendOtpMutation();
  const [verifyOtp] = useSellerVerifyOtpMutation();
  const [postSignup] = usePostSignUpMutation();

  const handleImageUpload = async (file: any) => {
    if (file?.type.includes("image")) {
      try {
        const res = await UploadMedia(file);
        if (res?.statusCode === 200) {
          setImage(res?.data);
        } else {
          errorToast(translation.validations.images_allowed);
        }
      } catch (error: any) {
        console.log(error);
      }
    } else {
      setImage("");
      errorToast(translation.validations.images_allowed);
    }
  };

  const handleChangePhone = (phone: any, country: any) => {
    setPhoneCode(country?.dialCode);
    profileSetupFormik.setFieldValue(
      "phone",
      phone?.replace(country.dialCode, "")
    );
  };

  const getUserDetails = async (token: any) => {
    try {
      const result = await getProfile({}).unwrap();
      if (result?.statusCode === 200) {
        dispatch(
          setCredentials({
            token: token,
            user: result?.data || null,
          })
        );
      }
      return result;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  const profileSetupFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      fullName: "",
      email: user?.email || "",
      phone: user?.phone || "",
      landline: "",
      address: "",
      lat: "",
      long: "",
      state: "",
      license: "",
    },

    validationSchema: Yup.object({
      email: Yup.string()
        .required(translation.validations.email_required)
        .matches(
          /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
          translation.validations.valid_email
        ),
      fullName: Yup.string()
        .required(translation.validations.required_field)
        .min(3, translation.validations.min_three),

      phone: Yup.string()
        .required(translation.validations.required_field)
        .min(8, translation.validations.correct_phone)
        .max(14)
        .label(translation.Globals.phone_number),
      landline: Yup.string()
        // .required(translation.validations.required_field)
        .min(7, translation.validations.min_seven)
        .max(15, translation.validations.max_fifteen),

      address: Yup.string().required(translation.validations.required_field),
      license: Yup.string().min(3, translation.validations.min_three),
    }),

    onSubmit: async (values) => {
      profileSetupFormik.setSubmitting(true);
      let data = {
        logoImage: image,
        businessName: profileSetupFormik?.values?.fullName,
        countryCode: (phoneCode.includes("+") ? "" : "+") + phoneCode,
        phone: profileSetupFormik?.values?.phone,
        landline: profileSetupFormik?.values?.landline,
        email: profileSetupFormik?.values?.email,
        licenseNo: profileSetupFormik?.values?.license,
        address: profileSetupFormik?.values?.address,
      };

      const registrationData = {
        key: profileSetupFormik?.values?.email,
        becomeSeller: true,
        countryCode: (phoneCode.includes("+") ? "" : "+") + phoneCode,
        phone: profileSetupFormik?.values?.phone,
        businessName: profileSetupFormik?.values?.fullName,
        businessLandline: profileSetupFormik?.values?.landline,
        address: profileSetupFormik?.values?.address,
        licenseNo: profileSetupFormik?.values?.license,
        logoImage: image,
        isProfileComplete: true,
      };

      if (!image) {
        setError(true);
        return;
      }

      if (!checked) {
        errorToast(translation.Globals.accept_terms);
        return;
      }

      if (!phoneVerified && !user?.isPhoneVerify) {
        errorToast(translation.toast_messages.verify_phone);
        return;
      } else if (!emailVerified && !user?.isEmailVerify) {
        errorToast(translation.toast_messages.verify_email);
        return;
      }

      const token = getFromStorage(STORAGE_KEYS.token);
      if (user) {
        try {
          let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;
          const response = await postSeller(encryptedBody).unwrap();
          if (response?.statusCode === 200) {
            // setOpenSellerModal(false);
            profileSetupFormik.resetForm();
            try {
              const result = await getProfile({}).unwrap();
              if (result?.statusCode === 200) {
                dispatch(
                  setCredentials({
                    token: JSON.parse(`${token}`),
                    user: result?.data || null,
                  })
                );
              }
            } catch (error) {
              console.log(error);
            }
            window.scrollTo({ top: 0, behavior: "smooth" });
            successToast(translation?.Globals.details_added);
            navigate("/");
          } else {
            errorToast(response?.message || "");
          }
        } catch (error: any) {
          errorToast(error?.data?.message || "");
          console.log(error);
        }
      } else {
        try {
          let encryptedBody = generateEncryptedKeyBody(
            registrationData
          ) as CommonBody;
          const response = await postSignup(encryptedBody).unwrap();
          if (response?.statusCode === 200) {
            setToStorage(
              STORAGE_KEYS.token,
              JSON.stringify(response?.data?.token)
            );
            setToStorage(
              STORAGE_KEYS.userData,
              JSON.stringify(response?.data || "")
            );

            dispatch(
              setCredentials({
                token: response?.data?.token || "",
                user: response?.data || null,
              })
            );
            dispatch(
              temporaryToken({
                tempToken: null,
              })
            );
            // setOpenSellerModal(false);
            successToast(response?.message || "");
            navigate("/");
            getUserDetails(response?.data?.token);
          }
        } catch (error: any) {
          errorToast(error?.data?.message || "");
          console.log(error);
        }
      }
    },
  });

  const closeModal = () => {
    setVerificationPopup(false);
  };

  // for sending otp
  const sendVerificationCode = async (val: number) => {
    let data = {
      ...(val === 1
        ? {
            key: profileSetupFormik.values.phone,
            countryCode: (phoneCode.includes("+") ? "" : "+") + phoneCode,
          }
        : {
            key: profileSetupFormik.values.email,
          }),
    };

    if (
      profileSetupFormik.values?.phone?.length > 6 ||
      /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i.test(
        profileSetupFormik.values.email
      )
    ) {
      try {
        let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;
        const response = await sendOtp(encryptedBody).unwrap();
        if (response?.statusCode === 200) {
          successToast(response?.message || "");
          setCountDown(59);
          setVerificationPopup(true);
        }
      } catch (error: any) {
        errorToast(error?.data?.message || "");
      }
    }
  };

  //for otp verification
  const handleVerifyOtp = async () => {
    if (otp?.length === 4) {
      setError(false);

      const data = {
        ...(!isPhone
          ? { key: profileSetupFormik.values.email, code: otp }
          : {
              key: profileSetupFormik.values.phone,
              countryCode: (phoneCode.includes("+") ? "" : "+") + phoneCode,
              code: otp,
            }),
      };

      try {
        let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;
        const response = await verifyOtp(encryptedBody).unwrap();
        if (response?.statusCode === 200) {
          if (!isPhone) {
            setEmailVerified(true);
            setNewEmail(profileSetupFormik.values.email);
            setOtp("");
          } else {
            setPhoneVerified(true);
            setNewPhone(profileSetupFormik.values.phone);
            setNewCountryCode(phoneCode);
            setOtp("");
          }
          setVerificationPopup(false);
          successToast(response?.message || "");
        }
      } catch (error: any) {
        if (error?.data?.message) {
          errorToast(error?.data?.message || "");
        }
      }
    } else {
      setError(true);
    }
  };

  const handleResendOtp = async () => {
    let data = {
      ...(isPhone
        ? {
            phone: profileSetupFormik.values.phone,
            countryCode: (phoneCode.includes("+") ? "" : "+") + phoneCode,
          }
        : {
            key: profileSetupFormik.values.email,
          }),
      resend: true,
    };

    try {
      let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;
      const response = await sendOtp(encryptedBody).unwrap();
      if (response?.statusCode === 200) {
        successToast(response?.message || "");
        setCountDown(59);
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");
    }
  };
  useEffect(() => {
    if (user) {
      setEmailVerified(user?.isEmailVerify);
      setPhoneVerified(user?.isPhoneVerify);
      setNewCountryCode(user?.countryCode || "");
      setNewEmail(user?.email || "");
      setNewPhone(user?.phone || "");
      setPhoneCode(user?.countryCode || "+971");
    }
  }, [user]);

  useEffect(() => {
    if (verificationPopup) {
      if (countDown > 0) {
        setTimeout(() => {
          setCountDown(countDown - 1);
        }, 1000);
      } else {
        setCountDown(0);
      }
    }
  }, [countDown, verificationPopup]);
  ///////////////

  const fetchData = async () => {
    const url = `${WORDPRESS_URL}${END_POINTS.seasonalOffers}/62`;

    try {
      setLoading(true);
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Basic ${btoa(
            `${WORDPRESS_USERNAME}:${WORDPRESS_PASSWORD}`
          )}`,
        },
      });
      if (response?.ok) {
        setLoading(false);
        const responseData = await response.json();
        if (responseData?.status === 200) {
          setData(responseData?.data);
        }
      } else {
        setData(null);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error:", error);
      setLoading(false);
      throw error;
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Layout>
      {loading ? (
        <section className="blog_dtl blog_crd container">
          <Skeleton
            animation="wave"
            variant="rectangular"
            width={"100%"}
            height={"300px"}
          />
          <h2>
            {" "}
            <Skeleton
              variant="text"
              sx={{ fontSize: "2rem" }}
              width={"250px"}
              animation="wave"
            />
          </h2>
        </section>
      ) : (
        <>
          {" "}
          {data ? (
            <section className="home_banner_sc">
              {/* <a
        key={item?._id}
        href={
          item?.link?.startsWith("http") ? item?.link : `https://${item?.link}`
        }
        target="_blank"
        rel="noopener noreferrer"
      > */}
              <div
                className="blog_bnr"
                style={{
                  backgroundImage: `url(${data?.banner_section?.image})`,
                }}
              >
                <div className="container">
                  <h1 className="sell_bnr_cntnt">
                    {data?.banner_section?.heading || ""}
                  </h1>
                </div>
              </div>
              {/* </a> */}
            </section>
          ) : null}
          <section className="prsnl_data container">
            {data?.info ? (
              <div className="prsnl_data_lft">
                <h2>{data?.info?.heading || ""}</h2>
                <p
                  dangerouslySetInnerHTML={{
                    __html: data?.info?.description || "",
                  }}
                />
                <h2>{data?.info?.listing?.heading || ""}</h2>
                <ul>
                  {data?.info?.listing?.listing_data?.length
                    ? data?.info?.listing?.listing_data?.map(
                        (item: { title: any; description: any }, i: number) => {
                          return (
                            <li key={i}>
                              <h4>
                                <span>{i + 1}. </span>
                                {item?.title || ""}
                              </h4>
                              <p>{item?.description || ""}</p>
                            </li>
                          );
                        }
                      )
                    : null}
                </ul>
              </div>
            ) : null}
            <div className="prsnl_data_ryt">
              <Box>
                <div className="business_prfl_mdl">
                  <h2>{translation.Globals.seller_form}</h2>

                  <div className="mdl_scrll">
                    <div className="image" style={{ margin: "10px 0 20px" }}>
                      <label htmlFor="icon-button-file">
                        <Input
                          sx={{ display: "none" }}
                          id="icon-button-file"
                          type="file"
                          inputProps={{
                            accept: "image/png,image/jpeg/,image/jpg",
                          }}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            if (event.target.files && event.target.files[0]) {
                              setImage(
                                URL.createObjectURL(event.target.files[0])
                              );

                              handleImageUpload(event.target.files[0]);
                            }
                          }}
                        />

                        <figure>
                          {!image ? (
                            <LazyLoadImage
                              src="/static/images/picture.png"
                              alt="logo"
                              effect="blur"
                              width={"100%"}
                              height={"100%"}
                            />
                          ) : (
                            <LazyLoadImage
                              src={image}
                              alt="logo"
                              effect="blur"
                              width={"100%"}
                              height={"100%"}
                            />
                          )}
                        </figure>
                        <AddIcon />
                      </label>
                    </div>
                    {error && !image ? (
                      <h6 className="err_msg">
                        {translation.validations.required_field}
                      </h6>
                    ) : (
                      ""
                    )}{" "}
                    <form onSubmit={profileSetupFormik.handleSubmit}>
                      <InputField
                        placeholder={translation.Globals.business_name}
                        name="fullName"
                        id="fullName"
                        value={profileSetupFormik.values.fullName}
                        onChange={(val) => {
                          if (
                            val.target.value === " " ||
                            val.target.value.includes(".")
                          ) {
                          } else if (isString(val.target.value)) {
                            profileSetupFormik.handleChange(val);
                          }
                        }}
                        inputProps={{ maxLength: 80 }}
                        onBlur={profileSetupFormik.handleBlur}
                        helperText={
                          profileSetupFormik.touched.fullName &&
                          profileSetupFormik.errors.fullName
                        }
                      />
                      <div className="form_control">
                        <div className="vrfctn_dv">
                          <PhoneInput
                            country={"in"}
                            disabled={user?.isPhoneVerify}
                            value={phoneCode + profileSetupFormik.values.phone}
                            onChange={(phone: any, country: any) =>
                              handleChangePhone(phone, country)
                            }
                            onBlur={profileSetupFormik.handleBlur}
                            placeholder="0 (000) 000-000"
                            enableSearch={true}
                            inputStyle={{
                              width: "100%",
                              backgroundColor: "#EBEBEB",
                            }}
                          />
                          {!phoneVerified ||
                          newPhone !== profileSetupFormik.values.phone ||
                          newCountryCode !== phoneCode ? (
                            <div>
                              <button
                                style={{
                                  right: language === "ar" ? "unset" : "15px",
                                  left: language === "ar" ? "15px" : "unset",
                                }}
                                className={
                                  profileSetupFormik.values?.phone?.length === 0
                                    ? "vrfctn_btn2"
                                    : profileSetupFormik.values?.phone?.length <
                                      6
                                    ? "vrfctn_btn2"
                                    : "vrfctn_btn"
                                }
                                type="button"
                                onClick={() => {
                                  setIsPhone(true);
                                  sendVerificationCode(1);
                                }}
                              >
                                {profileSetupFormik.values?.phone?.length === 0
                                  ? ""
                                  : profileSetupFormik.values?.phone?.length < 6
                                  ? translation.profile.invalid_number
                                  : translation.profile.verify}
                              </button>
                            </div>
                          ) : user?.isPhoneVerify || phoneVerified ? (
                            <div>
                              <figure
                                className="verified"
                                style={{
                                  right: language === "ar" ? "unset" : "15px",
                                  left: language === "ar" ? "15px" : "unset",
                                }}
                              >
                                <LazyLoadImage
                                  src="/static/images/phonetick.png"
                                  alt="phonetick"
                                  effect="blur"
                                  width={"100%"}
                                  height={"100%"}
                                />
                              </figure>
                            </div>
                          ) : null}
                          {profileSetupFormik.touched.phone &&
                          profileSetupFormik.errors.phone ? (
                            <h6 className="err_msg">
                              {profileSetupFormik.touched.phone &&
                                profileSetupFormik.errors.phone}
                            </h6>
                          ) : (
                            ""
                          )}{" "}
                        </div>
                        <div style={{ marginBottom: 10 }} />
                      </div>
                      <div className="vrfctn_dv2">
                        <InputField
                          placeholder={
                            translation.Globals.business_landline_optional
                          }
                          name="landline"
                          inputProps={{ maxLength: 15 }}
                          id="landline"
                          value={profileSetupFormik.values.landline}
                          onChange={(val) => {
                            if (
                              val.target.value === " " ||
                              val.target.value === "."
                            ) {
                            } else if (isNumber(val.target.value)) {
                              profileSetupFormik.handleChange(val);
                            }
                          }}
                          onBlur={profileSetupFormik.handleBlur}
                          helperText={
                            profileSetupFormik.touched.landline &&
                            profileSetupFormik.errors.landline
                          }
                        />
                      </div>
                      <div className="vrfctn_dv2">
                        <InputField
                          placeholder={translation.Globals.business_email}
                          disabled={user?.isEmailVerify}
                          name="email"
                          inputProps={{ maxLength: 80 }}
                          id="email"
                          value={profileSetupFormik.values.email}
                          onChange={(val) => {
                            if (
                              val.target.value === " " ||
                              val.target.value === "."
                            ) {
                            } else {
                              profileSetupFormik.handleChange(val);
                            }
                          }}
                          onBlur={profileSetupFormik.handleBlur}
                          helperText={
                            profileSetupFormik.touched.email &&
                            profileSetupFormik.errors.email
                          }
                        />
                        {!emailVerified ||
                        newEmail !== profileSetupFormik.values.email ? (
                          <div>
                            <div>
                              <button
                                style={{
                                  right: language === "ar" ? "unset" : "15px",
                                  left: language === "ar" ? "15px" : "unset",
                                }}
                                className={
                                  !/^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i.test(
                                    profileSetupFormik.values.email
                                  )
                                    ? "vrfctn_btn2"
                                    : "vrfctn_btn"
                                }
                                type="button"
                                onClick={() => {
                                  setIsPhone(false);
                                  sendVerificationCode(0);
                                }}
                              >
                                {profileSetupFormik.values?.email?.length === 0
                                  ? ""
                                  : !/^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i.test(
                                      profileSetupFormik.values.email
                                    )
                                  ? translation.profile.invalid_email
                                  : translation.profile.verify}
                              </button>
                            </div>
                          </div>
                        ) : user?.isEmailVerify || emailVerified ? (
                          <div>
                            <figure
                              className="verified"
                              style={{
                                right: language === "ar" ? "unset" : "15px",
                                left: language === "ar" ? "15px" : "unset",
                              }}
                            >
                              <LazyLoadImage
                                src="/static/images/phonetick.png"
                                alt="phonetick"
                                effect="blur"
                                width={"100%"}
                                height={"100%"}
                              />
                            </figure>
                          </div>
                        ) : null}
                      </div>

                      <InputField
                        placeholder={translation.Globals.address}
                        name="address"
                        id="address"
                        value={profileSetupFormik.values.address}
                        onChange={(val) => {
                          if (
                            val.target.value === " " ||
                            val.target.value.includes(".")
                          ) {
                          } else if (isString(val.target.value)) {
                            profileSetupFormik.handleChange(val);
                          }
                        }}
                        inputProps={{ maxLength: 100 }}
                        onBlur={profileSetupFormik.handleBlur}
                        helperText={
                          profileSetupFormik.touched.address &&
                          profileSetupFormik.errors.address
                        }
                      />
                      <div className="vrfctn_dv2">
                        <InputField
                          placeholder={translation.Globals.license_no_optional}
                          name="license"
                          inputProps={{ maxLength: 30 }}
                          id="license"
                          value={profileSetupFormik.values.license}
                          onChange={(val) => {
                            if (
                              val.target.value === " " ||
                              val.target.value === "."
                            ) {
                            } else {
                              profileSetupFormik.handleChange(val);
                            }
                          }}
                          onBlur={profileSetupFormik.handleBlur}
                          helperText={
                            profileSetupFormik.touched.license &&
                            profileSetupFormik.errors.license
                          }
                        />
                      </div>
                      <FormControlLabel
                        control={<Checkbox />}
                        checked={checked}
                        name="remember"
                        onChange={() => setChecked(!checked)}
                        label={
                          <Typography variant="h6" fontSize="13px">
                            {translation.Globals.agree_to}{" "}
                            <a
                              className="terms_link"
                              href="/terms-and-conditions"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {translation.Globals.terms_conditions}
                            </a>
                          </Typography>
                        }
                      />
                      <Button
                        value={translation.Globals.submit}
                        onClick={() => setError(true)}
                      />
                    </form>
                    <p
                      style={{
                        fontSize: "11px",
                        color: "#404040",
                        paddingBottom: 0,
                      }}
                    >
                      {translation.Globals.verification_porocess}
                    </p>
                  </div>
                </div>
                {/* )} */}
              </Box>
            </div>
          </section>
          {data?.how ? (
            <section className="hwto_apply container">
              <h2>{data?.how?.heading || ""}</h2>
              <ul className="apply_lstng">
                {data?.how?.listing?.length
                  ? data?.how?.listing?.map((item: any, i: number) => {
                      return (
                        <li key={i}>
                          <figure>
                            <LazyLoadImage
                              src={item?.image}
                              alt=""
                              effect="blur"
                              width={"100%"}
                              height={"100%"}
                            />
                          </figure>
                          <h3>{item?.title || ""}</h3>
                          <p>{item?.content || ""}</p>
                          <h6>
                            Step <span>{i + 1}</span>
                          </h6>
                        </li>
                      );
                    })
                  : null}
              </ul>
            </section>
          ) : null}
          <Modal
            open={verificationPopup}
            onClose={closeModal}
            hideBackdrop
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <div className="AuthWrap">
                <div className="cross">
                  <CloseIcon
                    onClick={() => {
                      setVerificationPopup(false);
                    }}
                  />
                </div>
                <h2>{translation.Auth.otp_verification.verification}</h2>

                {isPhone ? (
                  <p>
                    {translation.Auth.otp_verification.phone_code}{" "}
                    <span>
                      {phoneCode && profileSetupFormik.values.phone
                        ? (phoneCode.includes("+") ? "" : "+") +
                          phoneCode +
                          profileSetupFormik.values.phone
                        : ""}
                    </span>
                  </p>
                ) : (
                  <p>
                    {translation.Auth.otp_verification.email_code}{" "}
                    <span>{profileSetupFormik.values.email || ""}</span>
                  </p>
                )}

                <FormControl className="opt_fields" sx={{ width: "100%" }}>
                  <OTPInput
                    value={otp}
                    onChange={setOtp}
                    numInputs={4}
                    renderInput={(props) => <input {...props} />}
                    inputStyle={otpStyle}
                    inputType="tel"
                    shouldAutoFocus
                  />
                  <br />
                  {error && otp.length !== 4 ? (
                    <h6 className="err_msg" style={{ textAlign: "center" }}>
                      {translation.validations.required_field}
                    </h6>
                  ) : (
                    ""
                  )}
                </FormControl>

                <Button
                  value={translation.Globals.next || ""}
                  onClick={handleVerifyOtp}
                />

                {countDown === 0 ? (
                  <div className="resend">
                    <h4 onClick={handleResendOtp}>
                      {translation.Auth.otp_verification.resend}
                    </h4>
                  </div>
                ) : (
                  <div className="continue" style={{ marginBottom: "10px" }}>
                    <h5>
                      {" "}
                      {translation.Auth.otp_verification.code_expire} 00 :{" "}
                      {countDown < 10 ? `0${countDown}` : countDown}
                    </h5>
                  </div>
                )}
              </div>
            </Box>
          </Modal>
        </>
      )}
    </Layout>
  );
};

export default Sell;
